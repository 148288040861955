import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { curation as curationPropType } from 'lib/CustomPropTypes';
import getLayout from 'components/layouts/getLayout';

const mapStateToProps = ({
  front,
  shared,
}) => ({
  curation: front.curation,
  vertical: shared.vertical,
});

function Curated({ curation, vertical, isFluidWidthPage }) {
  if (!curation.layouts) {
    return null;
  }

  const props = {
    adsDisabled: curation.adsDisabled,
    curationAutofill: curation.autofill,
    layouts: curation.layouts,
    pageRoute: curation.pageRoute,
    vertical,
    isFluidWidthPage,
  };

  const context = {};

  return curation.layouts.map(
    getLayout(props, context),
  );
}

Curated.propTypes = {
  curation: curationPropType.isRequired,
  vertical: PropTypes.string.isRequired,
  isFluidWidthPage: PropTypes.bool,
};

Curated.defaultProps = {
  isFluidWidthPage: false,
};

export default connect(mapStateToProps)(Curated);
