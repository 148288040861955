import React from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { connect } from 'react-redux';
import getConfig from 'next/config';
import get from 'lodash.get';

import { curation as curationPropType } from 'lib/CustomPropTypes';
import { shareImage } from 'lib/social';

const mapStateToProps = ({ front }) => ({
  curation: front.curation,
});

function Meta({
  curation,
  description,
  title,
}) {
  const {
    query: {
      vertical,
      domain,
    },
    asPath = '',
  } = useRouter();

  const canonicalUrl = `https://www.${domain}${asPath}`;

  // Image from curation
  const {
    socialImage: {
      url: image,
    } = {},
  } = curation || {};
  const imageUrl = image?.primary;

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />

      <link rel="canonical" href={canonicalUrl} />

      <meta name="thumbnail" content={shareImage(imageUrl, vertical)} />

      <meta property="og:description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:image" content={shareImage(imageUrl, vertical, 'og')} />
      <meta property="og:image:secure_url" content={shareImage(imageUrl, vertical, 'og')} />
      <meta property="og:image:type" content={get(image, 'encodingFormat', 'image/gif')} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />

      <script
        data-rh="true"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ // eslint-disable-line react/no-danger
          __html: JSON.stringify({
            '@context': {
              '@vocab': 'http://schema.org',
              pageType: {
                '@id': 'Text',
                '@type': '@id',
              },
              vertical: {
                '@id': 'Text',
                '@type': '@id',
              },
              subVertical: {
                '@id': 'Text',
                '@type': '@id',
              },
              section: {
                '@id': 'Text',
                '@type': '@id',
              },
              subSection: {
                '@id': 'Text',
                '@type': '@id',
              },
              packageId: {
                '@id': 'Text',
                '@type': '@id',
              },
              sponsor: {
                '@id': 'Text',
                '@type': '@id',
              },
              appVersion: {
                '@id': 'Text',
                '@type': '@id',
              },
            },
            '@type': 'Dataset',
            name: 'additionalTaxonomy',
            description: 'This is additional taxonomy that helps us with analytics',
            url: canonicalUrl,
            pageType: 'SectionFront',
            vertical,
            subVertical: '',
            section: asPath.slice(1), // Remove starting slash
            subSection: '',
            packageId: '',
            sponsor: '',
            appVersion: get(getConfig(), ['publicRuntimeConfig', 'appVersion'], ''),
          }),
        }}
      />
    </Head>
  );
}

Meta.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  curation: curationPropType.isRequired,
};

export default connect(mapStateToProps)(Meta);
